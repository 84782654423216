
    /* eslint-disable */
import React from 'react'
import { useCanvas, loadImage } from '@/utils'
import { v4 as uuid } from 'uuid'
import { useUser } from '@/contexts/user'

import styled from 'styled-components'
import { TopBar } from '@/ui/components/topbar'
import { Modal } from '@/ui/components/modal'
import { Link } from '@/ui/components/link'
import { List, ClickableListItem } from '@/ui/components/list'
import { DeleteIcon } from '@/ui/components/icons'
import colors from '@/ui/colors'

const BattlesList = styled(List)`
//width: 20vw;
`

const Centered = styled.div`
display: flex;
justify-content: center;
align-items: center;
`

const Line = styled(Centered)`
gap: .25em;
`

const Actions = styled(Centered)`
margin-top: 1em;
justify-content: space-evenly;
`

import { Uploader } from '@/ui/components/fileUploader'
import { FormItem } from '@/ui/components/form'
import { Button } from '@/ui/components/button'

import { UploadToCDN, ERROR } from '@/services/platform'
import { CreateMap } from '@/services/map'

const ErrorMessage = styled.span`
  color: red;
  font-size: .75em;
  max-width: 20em;
`
import { ProgressBar } from '@/ui/components/progressbar'

function dataURItoBlob(dataURI) {
  var binary = atob(dataURI.split(',')[1]);
  var array = [];
  for(var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], {type: 'image/png'});
}

const NewBattleModal = ({ open, onClose, onCreate }) => {
  const id = uuid()
  const { user } = useUser()
  const [name, setName] = React.useState('')
  const [map, setMap] = React.useState('')
  const [scale, setScale] = React.useState(1)
  const [error, setError] = React.useState(null)
  const [waiting, setWaiting] = React.useState(false)
  const [progress, setProgress] = React.useState({ progress: 0 })

  React.useEffect(() => {
    setError(null)
  }, [name, map, scale])

  const updateProgress = (p) => new Promise((res ) => setTimeout(() => {
    setProgress(s => ({ progress: p }))
    res()
  }, 1))

  const handleCreate = async () => {
    if (waiting || !name || !map || !scale) {
      setError('Missing information')
      console.log(waiting, name, map, scale)
      return false
    }
    setWaiting(true)

    const image = await loadImage(URL.createObjectURL(map))
    const maxSize = 500
    const ctx = useCanvas(null, { width: maxSize, height: maxSize })

    const w = Math.ceil(image.width/maxSize)
    const h = Math.ceil(image.height/maxSize)
    const length = w*h

    const steps = length + 1
    let done = 0

    const mapId = uuid()
    const tiles = []
    for (let i = 0; i < length; i++) {
      const col = i % w
      const row = Math.floor(i / w)
      ctx.clearRect(0, 0, maxSize, maxSize)
      ctx.drawImage(image,
        col * maxSize, row * maxSize,
        maxSize, maxSize,
        0, 0,
        maxSize, maxSize,
      )
      const data = ctx.canvas.toDataURL('png')
      const upload = await UploadToCDN(mapId, `${i}.png`, dataURItoBlob(data))
      if (upload.error) {
        if (upload.error == ERROR.INVALID_FORMAT) console.log('Invalid map format, must be a .jpg, .png or .jpeg image')
        if (upload.error == ERROR.SERVER) console.log('Error: Cant upload image, please report this error in the discord')
      }
      tiles.push(upload.url + upload.fields.key)
      done = i
      await updateProgress(done/steps)
    }

    const tiledmap = await CreateMap({
      id: mapId,
      tileSize: maxSize,
      width: w,
      height: h,
      tiles,
    })
    if (tiledmap.error) {
      if (upload.error == ERROR.INVALID_FORMAT) setError('Invalid map format, must be a .jpg, .png or .jpeg image')
      if (upload.error == ERROR.SERVER) setError('Error: Cant upload image, please report this error in the discord')
      return setWaiting(false)
    }
    await updateProgress(++done/steps)

    const created = await onCreate({
      id: uuid(),
      user: user.id,
      name,
      map: mapId,
      scale: parseFloat(scale)
    }) 
    if (!created) {
      setError('Error: Cant create battle, please report this error in the discord')
      return setWaiting(false)
    }

    await updateProgress(1)
    setWaiting(false)
    return created

    // const upload = await UploadToCDN(map.name, map)
    // if (upload.error) {
    //   if (upload.error == ERROR.INVALID_FORMAT) setError('Invalid map format, must be a .jpg, .png or .jpeg image')
    //   if (upload.error == ERROR.SERVER) setError('Error: Cant upload image, please report this error in the discord')
    //   return setWaiting(false)
    // }
    //
    // const created = await onCreate({
    //   id: uuid(),
    //   user: user.id,
    //   name,
    //   map: upload.url + upload.fields.key,
    //   scale: parseFloat(scale)
    // }) 
    // setWaiting(false)
    //
    // if (!created) setError('Error: Cant create battle, please report this error in the discord')
    // return created
  }

  return <Modal open={ open } onClose={ onClose }>
    <h3>Create new battlefield</h3>
    <FormItem>
      <label>Battlefield name</label>
      <input type="text" defaultValue={ name } onChange={ e => setName(e.target.value)}/>
    </FormItem>

    <FormItem>
      <label>Battlefield map</label>
      {/* <Uploader accept={ "image/jpg" } onSelected={ image => setMap(image) }/> */}
      <input type="file" onChange={ e => setMap(e.target.files[0]) }/>
    </FormItem>

    <FormItem>
      <label>Block scale</label>
      <input type="number" defaultValue={ scale } onChange={ e => setScale(e.target.value) }/>
    </FormItem>

    {
      waiting ?  <ProgressBar progress={ progress.progress }/>
      : <Button onClick={ handleCreate } disabled={ waiting }>Create</Button>
    }
    <ErrorMessage>{ error }</ErrorMessage>
  </Modal>
}

const PremiumModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
  margin: 1em;
  }

  p {
  margin: 0;
  }
`

const ButtonLink = styled.a`
text-decoration: none;
color: initial;
`

const PremiumModal = ({ open, onClose }) => <Modal open={ open } onClose={ onClose }>
  <PremiumModalContent>
  <h1>Support this project</h1>
  <h2>and enjoy unlimited battles</h2>
    <p><b>kriegsspiel.app</b> is free to use and it will be forever.
      But building, running and mantaining web applications is expensive and time intensive.</p>
    <p>That's why free accounts have a limitation of <b>one battle</b> at a time.</p>
    <p>Become a patron now and enjoy <b>kriegsspiel.app with unlimited battles</b></p>
    <ButtonLink href="https://patreon.com/kriegsspielapp" target="patreon">
      <Button>Become a patron</Button>
    </ButtonLink>
  </PremiumModalContent>
</Modal>

const Battles = ({ battles, onSelect, onCreate, onDelete }) => {
  const {user, logout} = useUser()
  const [newBattleOpen, setNewBattleOpen] = React.useState(false)
  const [showingBecomeAPatron, setShowingBecomeAPatron] = React.useState(!user.membership && !window.localStorage.getItem('patronshown'))

  const handleHidePatreonModal = () => {
    setShowingBecomeAPatron(false)
    window.localStorage.setItem('patronshown', '1')
  }

  const openNewBattleModal = () => {
    if (battles.length && !user.membership) return setShowingBecomeAPatron(true)
    setNewBattleOpen(true) 
  }

  const handleCreateBattle = async (data) => {
    const result = await onCreate(data)
    if (result) setNewBattleOpen(false)
    return result
  }

  return (<>
    <TopBar user={ user } onLogout={ logout }/>
    <Modal open={ true }>
      <BattlesList>
        { battles.map(battle => <Line key={ battle.id }>
          <ClickableListItem onClick={ () => onSelect(battle) }>
            <p>{ battle.name }</p>
          </ClickableListItem>
          <DeleteIcon onClick={ () => confirm('Are you sure?') && onDelete(battle.id) }/>
        </Line>)}
      </BattlesList>
      <Actions>
        <Link onClick={ openNewBattleModal }>Create new battle</Link>
        {/* <Link>Import a battle</Link> */}
      </Actions>
    </Modal>

    <NewBattleModal open={ newBattleOpen } onClose={ () => setNewBattleOpen(false) } onCreate={ handleCreateBattle }/>
    <PremiumModal open={ showingBecomeAPatron } onClose={ handleHidePatreonModal }/>

  </>)
}

export default Battles
