import React from 'react'
import styled from 'styled-components'
import colors from '@/ui/colors'
import { Link } from '@/ui/components/link'

import {
  ClickableIcon,
  ExitIcon,
} from '@/ui/components/icons'

export const TopBarContainer = styled.div`
position: fixed;
z-index: 9;
top: 0;
left: 0;
right: 0;
padding: 0.25em 1em;

background: white;
border-bottom: 2px solid ${ colors.dark };

-webkit-box-shadow: 10px 4px 21px -4px rgba(0,0,0,0.54);
-moz-box-shadow: 10px 4px 21px -4px rgba(0,0,0,0.54);
box-shadow: 10px 4px 21px -4px rgba(0,0,0,0.54);

display: flex;
justify-content: space-between;

& > div {
display: flex;
gap: 1em;
}
`

export const TopBar = ({ user, onExit, onConfiguration, onBlocks, onExport, onScreenshot, onLogout }) => <TopBarContainer>
  <div>
    { onExit && <Link onClick={ onExit }>
      Exit Battle
    </Link> }

    { onConfiguration && <Link onClick={ onConfiguration }>
      Battle Config
    </Link> }

    { onBlocks && <Link onClick={ onBlocks }>
      Blocks
    </Link> }

    { onExport && <Link onClick={ onExport }>
      Export
    </Link> }

    { onScreenshot && <Link onClick={ onScreenshot }>
      Screenshot
    </Link> }
  </div>
  <div>
    { user.email }
    <ClickableIcon onClick={ onLogout }>
      <ExitIcon/>
    </ClickableIcon>
  </div>
</TopBarContainer>
