import React from 'react'

import screen from '@context/screen'
import { useUser } from '@/contexts/user'
import {
  useBattle,

  SetBattleConfigAction,
  NewBlockAction,
  DeleteBlockAction,
  ClearBlocksAction,
  ClearPathsAction,
} from '@/stores/battle'

import { SetMapPinEvent } from '@/events'

import { useCanvas, screenshot, exportJSON } from '@/utils'
import Loop from '@/loop'
import Battle from '@/battle'

import { TopBar } from '@/ui/components/topbar'
import { Toolbar } from './toolbar'
import { HierarchySidebar } from './hierarchysidebar'

import MODAL, { BugModal, BlocksModal, ConfigurationModal} from './modals'

const Game = ({ onExit }) => {
  const {user, logout} = useUser()
  const [battle, dispatch] = useBattle()
  const [running, setRunning] = React.useState(false)
  const [mainLoop, setMainLoop] = React.useState(Loop({ fps: 40 }))
  const [showHierarchy, setShowHierarchy] = React.useState(false)
  const [showBugForm, setShowBugForm] = React.useState(false)

  const [modal, setModal] = React.useState(MODAL.NONE)

  const canvas = React.useRef()

  const handleExit = () => {
    mainLoop.stop()
    onExit()
  }

  const initBattle = async () => {
    const r = await Battle({ battle, canvas, dispatch})
    mainLoop.onUpdate(r.onUpdate)
    mainLoop.onRender(r.onRender)
    mainLoop.start()
  }

  React.useEffect(() => {
    if (running || !battle || !canvas) return
    setRunning(true)
    initBattle()
  }, [battle])

  return <>
    { running && <>
      <TopBar
        user={ user }
        onExit={ handleExit }
        onConfiguration={ () => setModal(state => state == MODAL.CONFIGURATION ? 0 : MODAL.CONFIGURATION) }
        onBlocks={ () => setModal(state => state == MODAL.BLOCKS ? 0 : MODAL.BLOCKS) }
        onLogout={ logout }
      />

      <BlocksModal
        open={ modal == MODAL.BLOCKS }
        blocks={ battle.blocks }
        onNew={ block => dispatch(NewBlockAction(block)) }
        onDelete={ blockid => dispatch(DeleteBlockAction(blockid)) }
        onClose={ () => setModal(MODAL.NONE) }
      />

      <ConfigurationModal
        open={ modal == MODAL.CONFIGURATION }
        battle={ battle }
        onChange={ (config) => dispatch(SetBattleConfigAction(config)) }
        onClearBlocks={ () => dispatch(ClearBlocksAction())}
        onClearPaths={ () => dispatch(ClearPathsAction())}
        onClose={ () => setModal(MODAL.NONE) }
      />

      <BugModal
        open={ showBugForm }
        battle={ battle.id }
        onClose={ () => setShowBugForm(false) }
      />

      <Toolbar
        onExit={ handleExit }
        onExport={ () => exportJSON(battle, `${battle.name}.${battle.id}.battle`) }
        onScreenshot={ () => screenshot(canvas, `${battle.name}.${battle.id}.screenshot`) }
        onMapPin={ () => SetMapPinEvent.emit() }
        onShowHierarchy={ setShowHierarchy }

        bugFormShown={ showBugForm }
        onShowBugForm={ setShowBugForm }
      />

      { battle.blocks.length && <HierarchySidebar open={ showHierarchy } blocks={ battle.blocks }/> }
    </> }
    <canvas ref={ canvas }/>
  </>
}

export default Game
