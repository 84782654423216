import { components } from '@/ECS'

const mask = components.INTERACTABLE_COMPONENT |
    components.TROOP_COMPONENT

export const SelectSystem = (ecs, input) => {
    const selected = []
    ecs.query(mask).forEach(e => {
        const interactable = ecs.getComponent(e, components.INTERACTABLE_COMPONENT)
        interactable.selected = interactable.hover || (interactable.selected && input.isPressed('Shift'))
        if (interactable.selected) selected.push(e)
    })
    return selected
}
