import ECSComponents from './components'

const component_keys = Object.keys(ECSComponents).sort((a, b) => ECSComponents[a] - ECSComponents[b])

const offsets = component_keys.reduce((l, k, idx) => {
    l[ECSComponents[k]] = idx
    return l
}, {})
const ENTITY_OFFSET = component_keys.length

export const ECS = () => {
    const entities = []
    const components = []
    const getComponent = (entity, component) => components[offsets[component] + (entity * ENTITY_OFFSET)]
    const getEntity = (entity) => component_keys.reduce((l, k) => { l[k] = getComponent(entity, ECSComponents[k]); return l} , {})

    const clear = () => {
        entities.length = 0
        components.length = 0
    }

    const NewEntity = (c) => {
        const id = entities.length

        const { mask, composition } = c.reduce((l, c) => {
            l.composition[c.id] = c
            l.mask |= c.id
            return l
        }, { mask: 0, composition: {} })
        composition[ECSComponents.MASK_COMPONENT] = {
            id: ECSComponents.MASK_COMPONENT,
            mask,
        }

        component_keys.forEach(k => {
            components.push(composition[ECSComponents[k]] || null)
        })
        return entities.push(id) - 1
    }

    const query = (mask) => ({
        forEach: (fn) => {
            for (let entity = 0; entity * ENTITY_OFFSET < components.length; entity++) {
                if ((getComponent(entity, ECSComponents.MASK_COMPONENT).mask & mask) != mask) continue
                fn(entity)
            }
        },
    })

    const serialize = (ids) => {
        const s = []
        const toSerialize = ids || entities

        return toSerialize.map(entity => component_keys.map(c => {
            const component = getComponent(entity, ECSComponents[c])
            if (!component) return
            return Object.keys(component).map(k => component[k])
        }).filter(k => k))
        //
        // for (let entity = 0; entity * ENTITY_OFFSET < components.length; entity++) {
        //     s.push(component_keys.map(c => {
        //         const component = getComponent(entity, ECSComponents[c])
        //         if (!component) return
        //         return Object.keys(component).map(k => component[k])
        //     }).filter(k => k))
        // }
        // return s
    }

    return {
        ...ECSComponents,
        entities,
        components,

        getComponent,
        getEntity,

        NewEntity,
        query,

        serialize,
        clear,
    }
}
