import { components } from '@/ECS'

const mask = components.TRANSFORM_COMPONENT | components.INTERACTABLE_COMPONENT |
    components.TROOP_COMPONENT

export const SelectAABBSystem = (ecs, box, input) => {
    const selected = []
    ecs.query(mask).forEach(e => {
        const { x, y } = ecs.getComponent(e, components.TRANSFORM_COMPONENT)
        const interactable = ecs.getComponent(e, components.INTERACTABLE_COMPONENT)

        interactable.selected = (x > box.a.x && x < box.b.x && y > box.a.y && y < box.b.y) || (interactable.selected && input.isPressed('Shift'))
        if (interactable.selected) selected.push(e)
    })
    return selected
}
