import React from 'react'
import styled from 'styled-components'
import colors from '@/ui/colors'

export const Link = styled.span`
cursor: pointer;
text-decoration: underline;
&:hover {
color: ${ colors.dark }
}
`
