import { loadImage } from '@/utils'
import { RenderSystem } from '@/ECS/systems/render'
import { FetchMap } from '@/services/map'

const TiledMap = async (map) => {
    const Index = (x, y) => x+y*map.width
    const tiles = Array.from({ length: map.tiles.length })
    const loading = Array.from({ length: map.tiles.length })
    let loaded = 0

    const loadTile = async (x, y) => {
        const idx = Index(x, y)
        if (loading[idx]) return
        loading[idx] = true
        tiles[idx] = await loadImage(map.tiles[idx])
        loaded++
        console.log('Map tile %d loaded (%d/%d)', idx, loaded, loading.length)
    }

    const draw = (ctx, x0, y0, w, h) => {
        const t = [
            Math.ceil(w/map.tileSize),
            Math.ceil(h/map.tileSize),
        ]
        const offset = [
            Math.max((x0/map.tileSize)|0, 0),
            Math.max((y0/map.tileSize)|0, 0),
        ]

        const n = [
            Math.min(offset[0] + t[0], map.width - 1),
            Math.min(offset[1] + t[1], map.height - 1)
        ]

        for(let x = offset[0]; x <= n[0]; x++) {
            for(let y = offset[1]; y <= n[1]; y++) {
                const idx = Index(x, y)
                if (!tiles[idx]) loadTile(x, y)
                else ctx.drawImage(tiles[idx], map.tileSize * x, map.tileSize * y)
            }
        }
    }

    return {
        draw
    }
}


const Battlefield = async (mapId) => {
    const map = await FetchMap(mapId)
    const tiledMap = await TiledMap(map)

    const frame = (ecs, camera, ctx) => {
        tiledMap.draw(ctx,
            -camera.pos.x,
            -camera.pos.y,
            ctx.canvas.width*camera.pos.z,
            ctx.canvas.height*camera.pos.z,
        )
        RenderSystem(ecs, ctx)
    }

    return {
        frame,
    }
}

export default Battlefield
