import { components } from '@/ECS'

const mask = components.TRANSFORM_COMPONENT | components.INTERACTABLE_COMPONENT
export const HoverSystem = (ecs, cursor) => {
    let done = []
    ecs.query(mask).forEach(e => {
        const { x, y, s } = ecs.getComponent(e, components.TRANSFORM_COMPONENT)
        const interactable = ecs.getComponent(e, components.INTERACTABLE_COMPONENT)
        const isPath = !!ecs.getComponent(e, components.PATH_COMPONENT)

        interactable.hover = Math.sqrt(((x - cursor.x) ** 2) + ((y - cursor.y) ** 2)) <= 5 * s
        if (!interactable.hover) return
        done.push(e)
    })
    return done
}
