import React from 'react'
import { Modal } from '@/ui/components/modal'
import { Button } from '@/ui/components/button'

import { SendBug } from '@/services/platform'

export const BugModal = ({ battle, open, onClose }) => {
  const [text, setText] = React.useState('')

  const send = async () => {
    await SendBug(battle, text)
    setText('')
    onClose()
  }

  return (
    <Modal open={ open } onClose={ onClose }>
      <h2>Have you find a bug or a missfunction?</h2>
      <h4>Describe it as clear as possible</h4>
      <div>
        <textarea rows="4" cols="50" onChange={ e => setText(e.target.value) }/>
        <Button onClick={ send }>Send</Button>
      </div>
    </Modal>
  )
}
