const ECSComponents = {
  MASK_COMPONENT: 0x01,
  TRANSFORM_COMPONENT: 0x02,
  RENDER_COMPONENT: 0x04,

  INTERACTABLE_COMPONENT: 0x08,
  TROOP_COMPONENT: 0x10,
  INFORMATION_COMPONENT: 0x20,
  ID_COMPONENT: 0x40,
  PATH_COMPONENT: 0x80,
  DELETABLE_COMPONENT: 0x100,
}

export default ECSComponents
