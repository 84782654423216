import React from 'react'
import { v4 as uuid } from 'uuid'
import {
  useCanvas,
  loadImage,
  Vec2
} from '@/utils'
import Battlefield from '@/battlefield'

import {
  FocusEvent,
  SelectModeEvent,
  SelectColorEvent,
  SetMapPinEvent,
  ZoomEvent,
} from '@/events'

import {
  watch,
  reducer,

  NewPathAction,
  DeletePathAction,
  UpdateBlocksAction,
  SetCameraAction,
} from '@/stores/battle'

import { mouse, isPressed,
  bind as BindInput,
  onKeyPressed,
  onWheel,
  onMouseMove,
  onMouseDown,
  onMouseClick,
  onMouseRightClick,
  onMouseDrag,
  onMouseMiddleDrag,
} from '@context/input'

import screen from '@context/screen'
import Camera from '@/camera.js'
import ECS, { components as ECSComponents, newBlock, newPath, migrate } from '@/ECS'
import {
  HoverSystem,
  SelectSystem,
  DragSystem,
  RotateSystem,
  RenderPathSystem,
  DeletePathSystem,
  SelectUnitSystem,
  SelectAABBSystem,
  SwitchTroopStatusSystem,
  ToggleTroopsFlagsSystem,
} from '@/ECS/systems'

import { FLAGS } from '@/blocks'

import modes from '@/modes'

const Battle = async (battle, dispatch) => {
  console.log('battle init', battle)
  const world = await Battlefield(battle.map)
  let selected

  BindInput()
  const camera = Camera({
    ...battle.camera,
    minZoom: 10,
  })
  const ecs = ECS()

  let updated = []
  const saveBattle = () => {
    /* eslint-disable-next-line */
    dispatch(UpdateBlocksAction(migrate(ecs, [...new Set(updated)]).blocks))
    updated.length = 0
  }

  let mode = modes.INTERACT
  SelectModeEvent.subscribe(({ detail }) => {
    mode = detail
  })

  const updateBlocks = () => {
    selected = []
    ecs.clear()
    battle.blocks.forEach(block => newBlock(ecs, { ...block, s: battle.scale }))
    battle.paths.forEach(path => newPath(ecs, { ...path, s: battle.scale }))
  }
  updateBlocks()

  watch((u) => {
    battle = u
    updateBlocks()
  })

  SetMapPinEvent.subscribe(() => {
    dispatch(SetCameraAction(camera.pos))
  })

  FocusEvent.subscribe(({ detail }) => {
    if (!detail) return
    const t = {
      x: -detail.x + (screen.width/2*camera.pos.z),
      y: -detail.y + (screen.height/2*camera.pos.z),
    }
    camera.moveTo(t)
  })

  const zoom = direction => {
    const zoomSpeed = .05 * (isPressed('Shift') ? 10 : 1)
    if (camera.zoom(zoomSpeed * direction)) camera.moveTo({
      x: camera.pos.x + -screen.width/2 * zoomSpeed * direction,
      y: camera.pos.y + -screen.height/2 * zoomSpeed * direction,
    })
  }

  ZoomEvent.subscribe(({ detail }) => zoom(detail))

  let color = '#e01b24'
  SelectColorEvent.subscribe(({ detail }) => {
    color = detail
  })

  let selectRect
  let points
  let hovered
  onMouseDrag((origin, target) => {
    switch(mode) {
      case modes.INTERACT:
        if (!isPressed('Shift')) {
          const dragging = DragSystem(ecs, Vec2.sub(camera.toWorld(origin), camera.toWorld(target)))
          if (dragging.length) {
            updated.push(...dragging)
            break
          }
        }

        if (!hovered.length) selectRect = true
      break
      case modes.DRAW:
        if (!points || !mouse.down) break
        const point = camera.toWorld(target)
        points.push(point.x, point.y)
      break
    }
  })

  onMouseRightClick((mouse) => {
    if (!selected?.length) return

    const origin = ecs.getComponent(selected[0], ECSComponents.TRANSFORM_COMPONENT)
    const target = camera.toWorld(mouse.pointer)

    updated.push(...DragSystem(ecs, Vec2.sub(origin, target)))
  })

  onMouseMove(({ pointer }) => {
    hovered = HoverSystem(ecs, camera.toWorld(pointer))
  })

  onMouseMiddleDrag((origin, target) => {
    camera.move(Vec2.mul(Vec2.sub(target, origin), { x: camera.pos.z, y: camera.pos.z }))
  })

  onMouseDown(() => {
    switch(mode) {
      case modes.INTERACT:
        if (selected?.length) break
        selected = SelectSystem(ecs, { isPressed })
        break
      case modes.DRAW:
        points = []
        break
    }
  })

  onMouseClick(() => {
    switch(mode) {
      case modes.INTERACT:
        selected = SelectSystem(ecs, { isPressed })

        if (mouse.dblclick && hovered) {
          const info = ecs.getComponent(hovered[0], ECSComponents.INFORMATION_COMPONENT)
          if (info) {
            selected = SelectUnitSystem(ecs, info.unit)
          }
        }

        if (selectRect) {
          const a = camera.toWorld(mouse.clickPoint)
          const b = Vec2.add(a, Vec2.sub(camera.toWorld(mouse.pointer), a))
          selected = SelectAABBSystem(ecs, { 
            a: {
              x: a.x > b.x ? b.x : a.x,
              y: a.y > b.y ? b.y : a.y,
            },
            b: {
              x: a.x < b.x ? b.x : a.x,
              y: a.y < b.y ? b.y : a.y,
            }
          }, { isPressed })
        }

        break
      case modes.DRAW:
        DeletePathSystem(ecs).forEach(e => {
          const { uuid } = ecs.getComponent(e, ECSComponents.ID_COMPONENT)
          dispatch(DeletePathAction(uuid))
        })

        if (points?.length) {
          const path = {
            x: points[0],
            y: points[1],
            s: .5,
            color,
            points,
            uuid: uuid()
          }

          newPath(ecs, path)
          dispatch(NewPathAction(path))
          points = false
        }
        break
    }

    if (updated.length) saveBattle()

    selectRect = false
  })

  onWheel(delta => {
    switch(mode) {
      case modes.INTERACT:
        if (mouse.down) {
          updated = RotateSystem(ecs, delta)
          return
        }
        break
    }

    zoom(delta > 0 ? 1 : -1)
  })

  onKeyPressed((mouse, isPressed, key) => {
    switch(key) {
      case '+': zoom(1); break
      case '-': zoom(-1); break
    }

    if (!selected?.length) return

    switch(key) {
      case 'm':
        updated.push(...ToggleTroopsFlagsSystem(ecs, FLAGS.MOVING))
        break
      case 'r':
        updated.push(...ToggleTroopsFlagsSystem(ecs, FLAGS.ROUTED))
        break
      case 'l':
        updated.push(...ToggleTroopsFlagsSystem(ecs, FLAGS.LOW_AMMO))
        break
      case '1':
        updated.push(...ToggleTroopsFlagsSystem(ecs, FLAGS.ONE))
        break
      case '2':
        updated.push(...ToggleTroopsFlagsSystem(ecs, FLAGS.TWO))
        break
      case '3':
        updated.push(...ToggleTroopsFlagsSystem(ecs, FLAGS.THREE))
        break
    }
  })

  const onUpdate = () => {
    const x = (!!isPressed('a') - !!isPressed('d')) * (isPressed('Shift') ? 10 : 1)
    const y = (!!isPressed('w') - !!isPressed('s')) * (isPressed('Shift') ? 10 : 1)
    camera.move({ x, y })
  }

  const onRender = (ctx) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
    // ctx.drawImage(world.frame(ecs, camera, ctx.canvas), 0, 0, ctx.canvas.width, ctx.canvas.height)
    ctx.save()
    ctx.scale(1/camera.pos.z, 1/camera.pos.z)
    ctx.translate(camera.pos.x, camera.pos.y)
    world.frame(ecs, camera, ctx)


    if (points?.length) {
      ctx.lineWidth  = 3 * battle.scale
      ctx.lineCap = "round";
      ctx.strokeStyle = color
      ctx.beginPath();
      for (var i = 2; i < points.length-1; i+=2) {
        ctx.moveTo(points[i-2], points[i-1]);
        ctx.lineTo(points[i], points[i+1]);
      }
      ctx.stroke();
    }

    RenderPathSystem(ecs, ctx)
    ctx.restore()

    ctx.strokeText(`${camera.pos.x} - ${camera.pos.y} - z:${camera.pos.z}`, 10, 10)

    selectRect && ctx.strokeRect(
      mouse.clickPoint.x,
      mouse.clickPoint.y,
      mouse.pointer.x - mouse.clickPoint.x,
      mouse.pointer.y - mouse.clickPoint.y,
    )
  }

  return {
    onRender,
    onUpdate,
  }
}

const BattleComponent = async ({ battle, canvas, dispatch }) => {
  const logo = await loadImage('https://cdn.kriegsspiel.app/image.jpg')

  const ctx = useCanvas('canvas')
  ctx.fillRect(0, 0, screen.width, screen.height)

  ctx.save()
  ctx.font = "bold small-caps 60px Arial";
  ctx.drawImage(logo, 0, 0, screen.width, screen.width / screen.ratio)
  ctx.fillText('Loading Game...', screen.width/2 - 15*15, screen.height*.65)
  ctx.restore()

  const battleInstance = await Battle(battle, dispatch)

  const onRender = (deltatime) => {
    battleInstance.onRender(ctx, deltatime)
  }

  const onUpdate = (deltatime) => {
    battleInstance.onUpdate(deltatime)
  }

  return {
    onRender,
    onUpdate
  }
}

export default BattleComponent
