import { Vec2 } from '@/utils'

const Camera = (config) => {
  const pos = { x: config?.x || 0, y: config?.y || 0, z: config?.z || 1}
  const minZoom = config?.minZoom || 2.5

  const move = (v) => { Vec2.assign(pos, Vec2.add(pos, v)) }
  const moveTo = (v) => { Vec2.assign(pos, v) }
  const zoom = x => {
    if (pos.z - x < 0.05 || pos.z - x > minZoom) return false
    pos.z -= x
    return true
  }

  const toWorld = (v) => ({
    x: -pos.x + (v.x * pos.z),
    y: -pos.y + (v.y * pos.z)
  })

    return {
      pos,
      move,
      moveTo,
      zoom,
      toWorld,
    }
}

export default Camera
