import { HTTPfetch } from './httpfetch'
const config = {
  api: '/api'
}

export const FetchUserBattles = async () => {
  const res = await fetch(`${config.api}/battles`, {
    method: 'get',
    credentials: 'include'
  })
  if(res.status !== 200) {
    window.localStorage.clear()
    window.location.reload()
  }
  return res.json()
}

export const FetchBattle = async (id) => {
  const res = await fetch(`${config.api}/battles/${id}`, {
    method: 'get',
    credentials: 'include'
  })
  if(res.status !== 200) {
    window.localStorage.clear()
    window.location.reload()
  }
  return res.json()
}


export const CreateBlocks = async (battle, blocks) => {
  const res = await fetch(`${config.api}/battles/${battle.id}/blocks`, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(blocks),
  })

  if(res.status !== 200) return false
  return res.json()
}

export const UpdateBlocks = async (battle, blocks) => {
  const res = await fetch(`${config.api}/battles/${battle.id}/blocks`, {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(blocks),
  })

  if(res.status !== 200) return false
  return res.json()
}

export const DeleteBlocks = async (battle, blocks) => {
  const res = await fetch(`${config.api}/battles/${battle.id}/blocks`, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(blocks),
  })

  if(res.status !== 200) return false
  return res.json()
}


export const CreatePaths = async (battle, paths) => {
  const res = await fetch(`${config.api}/battles/${battle.id}/paths`, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(paths),
  })

  if(res.status !== 200) return false
  return res.json()
}

export const DeletePaths = async (battle, paths) => {
  const res = await fetch(`${config.api}/battles/${battle.id}/paths`, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(paths),
  })

  if(res.status !== 200) return false
  return res.json()
}


export const UpdateBattleConfig = async (id, data) => {
  const res = await fetch(`${config.api}/battles/${id}/config`, {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data),
  })

  if(res.status !== 200) return false
  return res.json()
}

export const UpdateBattleCamera = async (id, data) => {
  const res = await fetch(`${config.api}/battles/${id}/camera`, {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data),
  })

  if(res.status !== 200) return false
  return res.json()
}


export const ERROR = {
  CANT_CREATE: 0x01,
}

export const CreateBattle = async (battle) => {
  const res = await HTTPfetch(`${config.api}/battles/${ battle.id }`, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(battle),
  })
  if (res.error) return ({ error: ERROR.CANT_CREATE })
  return res
}

export const DeleteBattle = async (battle) => {
  const res = await fetch(`${config.api}/battles/${battle}`, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
  })

  if(res.status !== 200) return false
  return res.json()
}
