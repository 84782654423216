import components from './components'
export { components }

import { ECS } from './ecs'
export default ECS

export const newBlock = (ecs, {x, y, s, r, color, type, flags, name, unit, uuid}) => ecs.NewEntity([
    {
        id: components.ID_COMPONENT,
        uuid,
    },
    {
        id: components.TRANSFORM_COMPONENT,
        x, y, s, r,
    },
    {
        id: components.RENDER_COMPONENT,
        color,
    },
    {
        id: components.TROOP_COMPONENT,
        type,
        flags: flags || 0,
    },
    {
        id: components.INFORMATION_COMPONENT,
        name,
        unit,
    },
    {
        id: components.INTERACTABLE_COMPONENT,
        hover: false,
        selected: false,
    },
])

export const newPath = (ecs, {x, y, s, color, points, uuid}) => ecs.NewEntity([
    {
        id: components.ID_COMPONENT,
        uuid,
    },
    {
        id: components.TRANSFORM_COMPONENT,
        x, y,
        s,
        r: 0,
    },
    {
        id: components.RENDER_COMPONENT,
        color,
    },
    {
        id: components.PATH_COMPONENT,
        points,
    },
    {
        id: components.INTERACTABLE_COMPONENT,
        hover: false,
        selected: false,
    },
    {
        id: components.DELETABLE_COMPONENT,
        delete: false,
    },
])

const exportBlock = entity => {
    const [_2, x, y, s, r] = entity.find(component => component[0] == components.TRANSFORM_COMPONENT)
    const [_4, color] = entity.find(component => component[0] == components.RENDER_COMPONENT)
    const [_16, type, flags] = entity.find(component => component[0] == components.TROOP_COMPONENT)
    const [_32, name, unit]= entity.find(component => component[0] == components.INFORMATION_COMPONENT)
    const [_64, uuid]= entity.find(component => component[0] == components.ID_COMPONENT)
    /* eslint-enable */

    return ({
        uuid,
        x,
        y,
        s,
        r,
        color,
        type,
        flags,
        name,
        unit,
    })
}

const exportPath = entity => {
    const [_2, x, y, s, r] = entity.find(component => component[0] == components.TRANSFORM_COMPONENT)
    const [_4, color] = entity.find(component => component[0] == components.RENDER_COMPONENT)
    const [_64, uuid]= entity.find(component => component[0] == components.ID_COMPONENT)
    const [_128, points] = entity.find(component => component[0] == components.PATH_COMPONENT)
    const [_256, deleted] = entity.find(component => component[0] == components.DELETABLE_COMPONENT)
    if (deleted) return false

    return ({
        uuid,
        x,
        y,
        s,
        r,
        color,
        points,
    })
}

export const migrate = (ecs, entities) => ecs.serialize(entities).reduce((l, entity) => {
    const isPath = !!entity.find(component => component[0] == components.PATH_COMPONENT)
    if (isPath) {
        const path = exportPath(entity)
        if (path) l.paths.push(path)
    } else l.blocks.push(exportBlock(entity))
    return l
}, { blocks: [], paths: [] })


