import React from 'react'
import styled from 'styled-components'
import { Modal } from '@/ui/components/modal'
import { Button } from '@/ui/components/button'
import { Link } from '@/ui/components/link'

const Container = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
gap: 1em;

h2 {
margin: 0;
padding: 0;
text-align: center;
}
`

const ErrorMessage = styled.span`
  color: red;
  font-size: .75em;
  max-width: 20em;
`

import { Login as LoginService, Register } from '@/services/user'

const CreateUserModal = ({ open, onClose, onCreated }) => {
  const [email, setEmail] = React.useState(null)
  const [password, setPassword] = React.useState(null)
  const [repassword, setRepassword] = React.useState(null)
  const [error, setError] = React.useState(null)

  React.useEffect(() => {
    setError(null)
  }, [email, password, repassword])

  const handleRegisterAccount = async () => {
    if (!password || !email) return setError('Missing fields')
    /* eslint-disable-next-line */
    if (!(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email))) return setError('Email incorrect')
    if (!(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(password))) return setError('Password must be minimum 8 characters and include at least one letter and one number')
    if (password != repassword) return setError('Password confirmation doesnt match')

    const user = await Register({ email, password })
    if (!user) return setError('Can not register user')
    onCreated(user)
  }

  // return <Modal open={ open } onClose={ onClose }>
  //     <Container>
  //       <p>Kriegsspiel.app is in a private beta phase, new accounts are not allowed yet</p>
  //       <span>
  //       Join our <a href="https://discord.gg/SvdAuCZsVC" target="discord">discord</a> community for updates and new beta phases
  //       </span>
  //       {/* <p>Follow our <a href="https://www.patreon.com/kriegsspielapp">patreon page</a> for updates, we will go live soon :)</p> */}
  //     </Container>
  // </Modal>

  return <Modal open={ open } onClose={ onClose }>
      <Container>
        <h2>Register</h2>
        <ErrorMessage>{ error }</ErrorMessage>
        {/* <input type="text" placeholder="name" onChange={ e => setName(e.target.value) }/> */}
        <input type="text" placeholder="email" onChange={ e => setEmail(e.target.value) }/>
        <input type="password" placeholder="password" onChange={ e => setPassword(e.target.value) }/>
        <input type="password" placeholder="repeat password" onChange={ e => setRepassword(e.target.value) }/>
        <Button onClick={ handleRegisterAccount }>Register account</Button>
      </Container>
  </Modal>
}

const Login = ({ onLogin }) => {
  const [email, setEmail] = React.useState(null)
  const [password, setPassword] = React.useState(null)
  const [showCreate, setShowCreate] = React.useState(false)
  const [error, setError] = React.useState(null)

  React.useEffect(() => {
    window.localStorage.clear()
  }, [])

  React.useEffect(() => {
    setError(null)
  }, [email, password])
  
  const handleLogin = async () => {
    const user = await LoginService({ email, password })
    if (!user) return setError(true)
    onLogin(user)
  }

  return <>
    <Modal open={ true }>
      <Container>
        <ErrorMessage>{ error && 'Email or password incorrect.' }</ErrorMessage>
        <input type="text" placeholder="email" onChange={ e => setEmail(e.target.value) }/>
        <input type="password" placeholder="password" onChange={ e => setPassword(e.target.value) }/>
        <Button onClick={ handleLogin }>Login</Button>
        <Link onClick={ () => setShowCreate(true) }>Create account</Link>
        {/* <Link>Forgot my password</Link> */}
      </Container>
    </Modal>
    <CreateUserModal open={ showCreate } onClose={ () => setShowCreate(false) } onCreated={ onLogin }/>
  </>
}

export default Login
