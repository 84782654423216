import React from 'react'
import { FetchBattlefield, FetchBattle } from '@service/battle'
import { reducer } from './reducer'
import { SetBattleAction } from './actions'

export { watch } from './reducer'
export * from './actions'

const Battle = React.createContext()
Battle.displayName = 'Battle'

export const useBattle = () => React.useContext(Battle)

export const BattleProvider = ({ battleId, children }) => {
    const [battle, dispatch] = React.useReducer(reducer, null)

    React.useEffect(() => {
        console.log('fetch battle data', battleId)
        /* eslint-disable-next-line */
        // Promise.all([
        //     FetchBattlefield(props.battle.battlefield),
        //     FetchBattle(props.battle.id),
        // ]).then((data) => {
        //     setBattlefield(data[0])
        //     dispatch(SetBattleAction(data[1]))
        // })

        FetchBattle(battleId).then((data) => {
            dispatch(SetBattleAction(data))
        })
    }, [])

    return (
        <Battle.Provider value={[ battle, dispatch ]}>
            { children }
        </Battle.Provider>
    )
}

