import styled from 'styled-components'
import colors from '@/ui/colors'

export const List = styled.div`
display: flex;
flex-direction: column;
`

export const ListItem = styled.div`
min-height: 2em;
border: 1px solid grey;
margin: .125em;
flex-grow: 1;
display: flex;
justify-content: space-between;
padding: 0.25em 1em;

& > * {
    margin: 0.5em;
}
`

export const ClickableListItem = styled(ListItem)`
&:hover {
background: ${ colors.light };
cursor: pointer;
}
`
