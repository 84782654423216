import { components } from '@/ECS'

const mask = components.TRANSFORM_COMPONENT |
    components.INTERACTABLE_COMPONENT |
    components.TROOP_COMPONENT

export const DragSystem = (ecs, vector) => {
    let done = []
    ecs.query(mask).forEach(e => {
        const { selected } = ecs.getComponent(e, components.INTERACTABLE_COMPONENT)
        if (!selected) return

        const transform = ecs.getComponent(e, components.TRANSFORM_COMPONENT)
        transform.x -= vector.x
        transform.y -= vector.y
        done.push(e)
    })
    return done
}
