import { components } from '@/ECS'

const mask = components.INTERACTABLE_COMPONENT |
    components.INFORMATION_COMPONENT |
    components.TROOP_COMPONENT

export const SelectUnitSystem = (ecs, parent) => {
    const selected = []
    ecs.query(mask).forEach(e => {
        const interactable = ecs.getComponent(e, components.INTERACTABLE_COMPONENT)
        const { unit } = ecs.getComponent(e, components.INFORMATION_COMPONENT)
        interactable.selected = unit == parent
        if (!interactable.selected) return
        selected.push(e)
    })
    return selected
}
