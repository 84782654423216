import React from 'react'
import styled from 'styled-components'
import colors from '@/ui/colors'

export const ProgressBar = styled.div`
border: 2px solid #bfbfbf;
position: relative;
height: .75em;

&::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: ${props => props.progress * 100}%;
  background-color: ${ colors.light };
}

&::after {
  content: '${props => (props.progress * 100)|0 }%';
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: .7em;
}
`
