import React from 'react'
import { Modal } from '@/ui/components/modal'

const MODAL = {
  NONE: 0,
  CONFIGURATION: 1,
  BLOCKS: 2,
}
export default MODAL

export * from './blocks'
export * from './configuration'
export * from './bug'
