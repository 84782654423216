import { components } from '@/ECS'

const mask = components.INTERACTABLE_COMPONENT |
    components.PATH_COMPONENT | 
    components.DELETABLE_COMPONENT

export const DeletePathSystem = (ecs) => {
    let done = []
    ecs.query(mask).forEach(e => {
        const interactable = ecs.getComponent(e, components.INTERACTABLE_COMPONENT)
        const deletable = ecs.getComponent(e, components.DELETABLE_COMPONENT)

        if (!interactable.hover) return
        deletable.delete = true
        done.push(e)
    })
    return done
}
