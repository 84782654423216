const Loop = config => {
  const { fps } = config
  const frameSpan =  1000 / fps
  let frameCount = 0
  let animationFrameId
  let last
  let deltatime

  const onupdate = []
  const onrender = []

  const frame = () => {
    let now = Date.now()
    if (!last) last = now

    if ((now - last) > frameSpan) {
      deltatime = now - last
      last = now

      frameCount++
      onupdate.forEach(fn => fn(deltatime, frameCount))
      onrender.forEach(fn => fn(deltatime, frameCount))
    }
    animationFrameId = window.requestAnimationFrame(frame)
  }

  const onUpdate = (fn) => onupdate.push(fn)
  const onRender = (fn) => onrender.push(fn)

  const start = () => frame()
  const stop = () => {
    onupdate.length = 0
    onrender.length = 0
    last = 0
    window.cancelAnimationFrame(animationFrameId)
  }

  return {
    start,
    stop,
    onUpdate,
    onRender,
  }
}

export default Loop
