/* eslint-disable */
export const actions = {
    SET: 'BATTLE/SET',
    SET_CONFIG: 'BATTLE/CONFIG/SET',
    SET_CAMERA: 'BATTLE/CAMERA/SET',

    SET_NAME: 'BATTLE/CONFIG/NAME/SET',

    SET_BLOCKS: 'BATTLE/BLOCKS/SET',
    NEW_BLOCK: 'BATTLE/BLOCKS/NEW',
    DELETE_BLOCK: 'BATTLE/BLOCKS/DELETE',
    CLEAR_BLOCKS: 'BATTLE/BLOCKS/CLEAR',
    UPDATE_BLOCKS: 'BATTLE/BLOCKS/UPDATE',

    SET_PATHS: 'BATTLE/PATHS/SET',
    NEW_PATH: 'BATTLE/PATHS/NEW',
    DELETE_PATH: 'BATTLE/PATHS/DELETE',
    CLEAR_PATHS: 'BATTLE/PATHS/CLEAR',
}

export const SetBattleAction = (battle = {}) => ({
    type: actions.SET,
    battle
})

export const SetBattleConfigAction = (config) => ({
    type: actions.SET_CONFIG,
    config
})

export const SetNameAction = (name) => ({
    type: actions.SET_NAME,
    name
})

export const SetCameraAction = (camera) => ({
    type: actions.SET_CAMERA,
    camera
})

export const NewBlockAction = (block) => ({
    type: actions.NEW_BLOCK,
    block,
})

export const UpdateBlocksAction = (blocks) => ({
    type: actions.UPDATE_BLOCKS,
    blocks,
})

export const DeleteBlockAction = (block) => ({
    type: actions.DELETE_BLOCK,
    block,
})

export const ClearBlocksAction = () => ({
    type: actions.CLEAR_BLOCKS,
})



// export const SetPathsAction = (paths) => ({
//     type: actions.SET_PATHS,
//     paths,
// })

export const NewPathAction = (path) => ({
    type: actions.NEW_PATH,
    path,
})

export const DeletePathAction = (path) => ({
    type: actions.DELETE_PATH,
    path,
})

export const ClearPathsAction = () => ({
    type: actions.CLEAR_PATHS,
})
