import { FLAGS } from './flags'

const BLOCK_WIDTH = 300
const BLOCK_HEIGHT = 80

export const Infantry = (block) => {
  const c = document.createElement('canvas')
  c.width = BLOCK_WIDTH
  c.height = BLOCK_HEIGHT
  const ctx = c.getContext('2d')

  ctx.strokeStyle = ctx.fillStyle = block.color
  ctx.fillRect(0, 0, c.width, c.height)

  const unitSymbol = (size) => {
    const c = document.createElement('canvas')
    c.width = size.width
    c.height = size.height
    const ctx = c.getContext('2d')

    ctx.lineWidth = 2
    ctx.strokeStyle = ctx.fillStyle = block.color
    ctx.fillRect(0, 0, size.width, size.height)

    ctx.filter = 'brightness(140%)'

    if (block.inverted && (block.flags & FLAGS.MOVING)) {
      ctx.translate(size.width, size.height)
      ctx.scale(-1, -1)
    }

    ctx.beginPath()
    ctx.moveTo(0, size.height*.35)
    ctx.lineTo(size.width, size.height)
    ctx.stroke()

    ctx.moveTo(0, size.height)
    ctx.lineTo(size.width, size.height*.35)
    ctx.stroke()
    ctx.closePath()

    ctx.strokeRect(0, 0, size.width, size.height)
    ctx.fillRect(0, 0, size.width, size.height*.35)

    ctx.fillStyle = '#222'
    ctx.font = "bold small-caps 20px Arial";
    ctx.fillText(block.name, (size.width / 2) - (block.name.length / 2 * 10), size.height/4)

    ctx.font = "bold small-caps 16px Arial";
    ctx.fillText(block.unit, size.width*.05, size.height*.75)
    return c
  }

  if (block.flags & FLAGS.MOVING) {
    ctx.save()
    ctx.lineWidth = 4
    ctx.filter = 'brightness(140%)'
    ctx.strokeRect(0, 0, c.width, c.height)

    ctx.beginPath()
    ctx.moveTo(0, c.height/2)
    ctx.lineTo(c.width/5, 0)
    ctx.lineTo(c.width/5, c.height)
    ctx.lineTo(0, c.height/2)
    ctx.fill()
    ctx.restore()

    ctx.drawImage(unitSymbol({ width: c.width - c.width/5, height: c.height }), c.width/5, 0)
  } else ctx.drawImage(unitSymbol({ width: c.width, height: c.height }), 0, 0)

  return c
}
