import React from 'react'
import styled from 'styled-components'
import { Sidebar } from '@/ui/components/sidebar'

import { FocusEvent } from '@/events'

const Collapsible = styled.div`
  height: 0;
  overflow: hidden;

  ${props => props.open && `
    height: auto;
    div:hover {
      color: grey;
    }
  `}
`


export const HierarchySidebar = ({ open, blocks }) => {
  const [units, setUnits] = React.useState({})
  const [unitOpen, setUnitOpen] = React.useState(false)

  React.useEffect(() => {
      setUnits(blocks.reduce((l, block) => {
        if (!l[block.unit]) l[block.unit] = []
        l[block.unit].push(block)
        return l
      }, { 'Enemy': [] }))
  },[ blocks])

  if (!open) return
  return <Sidebar>
    { Object.keys(units).sort().map(unit => <div key={ unit }>
      <h2 onClick={ () => setUnitOpen(unit) }>{unit}</h2>
      <Collapsible open={ unit == unitOpen }>
      { units[unit].map(block => <div key={block.uuid} onClick={ () => FocusEvent.emit(block) }>{ block.name }</div>) }
      </Collapsible>
    </div>) }
  </Sidebar>
}
