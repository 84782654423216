/* eslint-disable no-undef */
import React from 'react'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import { Modal } from '@/ui/components/modal'
import { FormItem } from '@/ui/components/form'
import { Button } from '@/ui/components/button'
import { Link } from '@/ui/components/link'
import { DeleteIcon } from '@/ui/components/icons'

import { List, ListItem } from '@/ui/components/list'


const BattlesList = styled(List)`
-- overflow: scroll;
`
const Centered = styled.div`
display: flex;
justify-content: center;
align-items: center;
`

const Line = styled(Centered)`
gap: .25em;
`

const Form = styled(FormItem)`
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }


  & > div {
    display: flex;
    flex-grow: 1;
    gap: .5em;

    * {
      width: 100%;
    }
  }
`

export const BlocksModal = ({
  open,
  blocks,
  onNew,
  onDelete,
  onClose,
}) => {
  const [color, setColor] = React.useState('#000000')
  const [name, setName] = React.useState('')
  const [unit, setUnit] = React.useState('')
  const [type, setType] = React.useState(1)
  const [units, setUnits] = React.useState({})
  const [blocksFile, setBlocksFile] = React.useState(null)
  const form = React.useRef()

  React.useEffect(() => {
    setUnits(blocks.reduce((l, block) => {
      if (!l[block.unit]) l[block.unit] = []
      l[block.unit].push(block)
      return l
    }, { }))
  },[ blocks ])

  const handleCreate = () => {
    onNew({
      uuid: uuid(),
      x: 50 + (10 * blocks.length),
      y: 10,
      s: 1,
      r: 0,
      color,
      type,
      name,
      unit,
    })
  }

  const handleNewBlockForUnit = (unit) => {
    setUnit(unit)
    setColor(units[unit][0].color)
    form.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  }

  const createBlocksFromCSV = async (csv) => {
    const bs = csv.split(/[\n\r]{1,2}/)
    bs.forEach(async (data, idx) => {
      const [ type, color, name, unit ] = data.split(',')
      if (
        (!type || !color || !name || !unit) ||
        !Number.isInteger(+type) ||
        !/^#[a-fA-F0-9]{3,6}$/.test(color)
      ) return

      onNew({
        uuid: uuid(),
        x: 50 + (10 * (blocks.length + idx)),
        y: 10,
        s: 1,
        r: 0,
        color,
        type: +type,
        name,
        unit,
      })
    })
  }

  const handleImportCSV = () => {
    if (!blocksFile) return
    var reader = new FileReader();
    reader.onload = e => createBlocksFromCSV(e.target.result)
    reader.readAsText(blocksFile);
  }

  return <Modal open={ open } onClose={ onClose }>
    <h5>Create new block</h5>
    <Form>
      <div>
        <select onChange={ e => setType(+e.target.value)}>
          <option value="1">officer</option>
          <option value="2">Infantry</option>
          <option value="3">cavalry</option>
          <option value="4">artillery</option>
          <option value="5">supply</option>
        </select>
        <input type="color" defaultValue={ color } onChange={ e => setColor(e.target.value) }/>
      </div>

      <div>
        <input type="text" placeholder="block name" onChange={ e => setName(e.target.value) }/>
        <input ype="text" placeholder="unit name" defaultValue={ unit} onChange={ e => setUnit(e.target.value) }/>
      </div>

      <div ref={ form }>
        <Button onClick={ handleCreate }>Add</Button>
      </div>
    </Form>
    <h5>Or import from a CSV file</h5>
    <Form>
      <div>
        <input type="file" accept={ '.csv' } onChange={ e => setBlocksFile(e.target.files[0]) }/>
      </div>

      <div ref={ form }>
        <Button disabled={ !blocksFile } onClick={ handleImportCSV }>Import</Button>
      </div>
    </Form>

    <h3>Units & Blocks</h3>
    <BattlesList>
      {
        Object.keys(units).sort().map(k => {
          return <div key={ k }>
            <h5>{ k } (<Link onClick={ () => handleNewBlockForUnit(k) }>Add Block</Link>)</h5>
            { units[k].map(block => <Line key={ block.uuid }>
              <ListItem>
                <p>{ block.name } ({ block.unit })</p>
                <DeleteIcon onClick={ () => confirm('Are you sure?') && onDelete(block.uuid) }/>
              </ListItem>
            </Line>) }
          </div>
        })
      }
    </BattlesList>

  </Modal>
}
