import './styles/index.scss'

import React from 'react'
import { useUser } from '@/contexts/user'
import { BattleProvider } from '@/stores/battle'

import { CreateBattlefield, CreateBattle } from '@/services/battle'

import { root } from '@/ui'
import Battles from '@/ui/pages/battles'
import Game from '@/ui/pages/game'
import { TopBar } from '@/ui/components/topbar'

const b = window.localStorage.getItem('battle')

import { FetchUserBattles, DeleteBattle } from '@/services/battle'

const App = () => {
  const {user, logout} = useUser()
  const [battles, setBattles] = React.useState([])
  const [battle, setBattle] = React.useState(b ? JSON.parse(b) : '')

  const handleCreateBattle = async (data) => {
    const result = await CreateBattle(data)
    if (result.error) return false
    FetchUserBattles(user.id).then(setBattles)
    return result
  }

  const handleDeleteBattle = async (id) => {
    await DeleteBattle(id)
    FetchUserBattles(user.id).then(setBattles)
  }

  React.useEffect(() => {
    window.localStorage.setItem('battle', JSON.stringify(battle))
  }, [battle])

  React.useEffect(() => {
    FetchUserBattles(user.id).then(setBattles)
  }, [])

  if (!battle) return <Battles battles={ battles } onSelect={ setBattle } onCreate={ handleCreateBattle } onDelete={ handleDeleteBattle }/>
  return <BattleProvider battleId={ battle.id }>
    <Game onExit={ () => setBattle('') }/>
  </BattleProvider>
}

import Base from '@/ui/pages'

(async () => {
  root.render(<Base><App/></Base>)
})()
