import { components } from '@/ECS'

const mask = components.TRANSFORM_COMPONENT | components.PATH_COMPONENT | components.RENDER_COMPONENT

export const RenderPathSystem = (ecs, ctx) => {
    ctx.lineCap = "round";

    ecs.query(mask).forEach(e => {
        const deletable = ecs.getComponent(e, components.DELETABLE_COMPONENT)
        if (deletable.delete) return

        ctx.save()
        const { x, y, s } = ecs.getComponent(e, components.TRANSFORM_COMPONENT)
        const { points } = ecs.getComponent(e, components.PATH_COMPONENT)
        const { color } = ecs.getComponent(e, components.RENDER_COMPONENT)
        const interactable = ecs.getComponent(e, components.INTERACTABLE_COMPONENT)

        ctx.strokeStyle = color
        if (interactable?.hover) ctx.globalCompositeOperation = "lighten";

        ctx.lineWidth  = 3 * s
        ctx.beginPath();
        for (var i = 2; i < points.length-1; i+=2) {
            ctx.moveTo(points[i-2], points[i-1]);
            ctx.lineTo(points[i], points[i+1]);
        }
        ctx.stroke();

        ctx.fillStyle = '#333'
        ctx.shadowColor = "black";
        ctx.shadowBlur = 10;
        ctx.shadowOffsetX = 4;
        ctx.shadowOffsetY = 4;
        ctx.beginPath()
        ctx.arc(points[0], points[1], 2, 0, 2*Math.PI)
        ctx.fill()
        ctx.restore()
    })

}
