import { HTTPfetch } from './httpfetch'
const config = {
  api: '/api'
}

export const ERROR = {
  // INVALID_FORMAT: 0x01,
  SERVER: 0x02,
}

export const FetchMap = async (id) => {
  const response = await HTTPfetch(`${config.api}/maps/${ id }`, {
    method: 'GET',
    credentials: 'include',
  })
  if (response.error) return ({ error: ERROR.SERVER })
  return response
}


export const CreateMap = async (map) => {
  const response = await HTTPfetch(`${config.api}/maps`, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(map),
  })
  if (response.error) return ({ error: ERROR.SERVER })
  return response
}

