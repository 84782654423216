import React from 'react'

const User = React.createContext()
User.displayName = 'User'

export const useUser = () => React.useContext(User)
export const UserProvider = ({ user, logout, children }) => <User.Provider value={{ user, logout }}>
    { children }
</User.Provider>

