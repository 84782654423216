import { components } from '@/ECS'

const mask = components.TROOP_COMPONENT

export const ToggleTroopsFlagsSystem = (ecs, flag) => {
    const done = []
    ecs.query(mask).forEach(e => {
        const { selected } = ecs.getComponent(e, components.INTERACTABLE_COMPONENT)
        if (!selected) return

        const troop = ecs.getComponent(e, components.TROOP_COMPONENT)
        const has = troop.flags & flag
        const need = (flag & ~has)
        troop.flags = (troop.flags & ~has) | need
        done.push(e)
    })
    return done
}
