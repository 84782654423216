import React from 'react'
import styled from 'styled-components'
import colors from '@/ui/colors'

export const Button = styled.div`
    padding: .25em 1em;

    background: ${ colors.dark };
    border: 2px solid  ${ colors.dark };

    display: flex;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        background: ${ colors.light };
    }

  ${props => props.active && `
        background: ${ colors.light };
  `}

  ${props => props.disabled && `
        opacity: .3;
        pointer: none;
        pointer-events: none;
  `}
`
