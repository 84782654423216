const handleErrors = response => {
  if (!response.ok) throw new Error(response.statusText)
  return response
}

/* eslint-disable-next-line */
export const HTTPfetch = (url, data) => new Promise((res, rej) => {
  fetch(url, data)
    .then(handleErrors)
    .then(r => {
      const isJson = r.headers.get('content-type')?.includes('application/json');
      res(isJson ? r.json() : r)
    })
    .catch(({ message }) => res({ error: message }))
})
