const config = {
  api: '/api'
}

export const Login = async (credentials) => {
  const res = await fetch(`${config.api}/auth/login`, {
    method: 'post',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials),
  })

  if(res.status !== 200) return false
  return res.json()
}

export const Register = async (data) => {
  const res = await fetch(`${config.api}/auth/create`, {
    method: 'post',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data),
  })

  if(res.status !== 200) return false
  return res.json()
}

export const Session = async () => {
  const res = await fetch(`${config.api}/auth/check`, {
    method: 'get',
    credentials: 'include'
  })

  if(res.status !== 200) return false
  return res.json()
}

export const Logout = async () => {
  const res = await fetch(`${config.api}/auth/logout`, {
    method: 'get',
    credentials: 'include'
  })

  if(res.status !== 200) return false
  return res.json()
}
