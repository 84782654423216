import { render } from '@/blocks'
import { components } from '@/ECS'

const mask = components.TRANSFORM_COMPONENT |
    components.RENDER_COMPONENT |
    components.TROOP_COMPONENT

export const RenderSystem = (ecs, ctx) => {
    ctx.save()
    ctx.shadowColor = "black";
    ctx.shadowBlur = 10;
    ctx.shadowOffsetX = 4;
    ctx.shadowOffsetY = 4;
    ecs.query(mask).forEach(e => {
        ctx.save()

        const { x, y, r, s } = ecs.getComponent(e, components.TRANSFORM_COMPONENT)
        const { color } = ecs.getComponent(e, components.RENDER_COMPONENT)
        const interactable = ecs.getComponent(e, components.INTERACTABLE_COMPONENT)
        const troop = ecs.getComponent(e, components.TROOP_COMPONENT)
        const info = ecs.getComponent(e, components.INFORMATION_COMPONENT)

        ctx.translate(x, y)
        ctx.rotate(r * Math.PI / 180)

        if (interactable?.hover || interactable?.selected) ctx.filter = 'brightness(140%)'

        const image = render({
            type: troop.type,
            status: troop.status,
            flags: troop.flags,
            color,
            inverted: (r > 90 || r < -90),
            name: info.name,
            unit: info.unit,
        })

        ctx.drawImage(
            image,
            -image.width*.5*.1*s, -image.height*.5*.1*s,
            image.width*0.1*s, image.height*0.1*s,
        )

        ctx.restore()
    })
    ctx.restore()
}
