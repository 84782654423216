import { components } from '@/ECS'
const rotationSpeed = 5

const mask = components.TRANSFORM_COMPONENT |
    components.INTERACTABLE_COMPONENT |
    components.TROOP_COMPONENT

export const RotateSystem = (ecs, deg) => {
// export const RotateSystem = (ecs, direction) => {
    const done = []
    ecs.query(mask).forEach(e => {
        const { selected } = ecs.getComponent(e, components.INTERACTABLE_COMPONENT)
        if (!selected) return

        const transform = ecs.getComponent(e, components.TRANSFORM_COMPONENT)
        transform.r -= deg //((+direction * 2) - 1) * rotationSpeed
        // if (transform.r < -180) transform.r = 180
        // if (transform.r > 180) transform.r = -180
        transform.r %= 360
        done.push(e)
    })
    return done
}
