import React from 'react'
import styled from 'styled-components'

import {
  FocusEvent,
  SelectModeEvent,
  SelectColorEvent,
  ZoomEvent,
} from '@/events'
import modes from '@/modes'
import { useBattle } from '@/stores/battle'

import {
  SelectableIcon,

  HierarchyIcon,
  SelectIcon,
  DrawIcon,
  CameraIcon,
  ExportIcon,
  PinIcon,
  BugIcon,
  PlusIcon,
  MinusIcon,
} from '@/ui/components/icons'

const StyledToolbar = styled.div`
position: absolute;
${ props => props.side && `${props.side}: 0;` }
// left: 0;
bottom: 1em;

margin: 0;
padding: 0.25em 1em;

display: flex;
justify-content: space-between;
flex-direction: column;
gap: 2em;
`

const ToolbarSection = styled.div`
    display: flex;
    gap: .5em;
    flex-direction: column;

& div {
    display: flex;
    align-content: center;
    gap: .5em;
    & * {
        align-self: center;
    }
}
`

export const Toolbar = ({
  onShowHierarchy,

  onShowBugForm,
  bugFormShown,

  onScreenshot,
  onMapPin,
  onExport,
  onExit
}) => {
  const [battle, dispatch] = useBattle()
  const [color, setColor] = React.useState('#e01b24')
  const [mode, setMode] = React.useState(modes.INTERACT)
  const [showHierarchy, setShowHierarchy] = React.useState(false)

  React.useEffect(() => {
    SelectColorEvent.emit(color)
  }, [color])

  React.useEffect(() => {
    SelectModeEvent.emit(mode)
  }, [mode])

  React.useEffect(() => {
    onShowHierarchy(showHierarchy)
  }, [showHierarchy])

  if (!battle?.blocks) return null
  return (<>
    <StyledToolbar side='left'>
      <ToolbarSection>
        <SelectableIcon active={ true } onClick={ onMapPin }>
          <PinIcon/>
        </SelectableIcon>

        <SelectableIcon active={ true } onClick={ () => ZoomEvent.emit(1) }>
          <PlusIcon/>
        </SelectableIcon>
        <SelectableIcon active={ true } onClick={ () => ZoomEvent.emit(-1) }>
          <MinusIcon/>
        </SelectableIcon>
      </ToolbarSection>

      <ToolbarSection>
        <SelectableIcon active={ mode == modes.INTERACT } onClick={ e => setMode(modes.INTERACT) }>
          <SelectIcon/>
        </SelectableIcon>
        <div>
          <SelectableIcon active={ mode == modes.DRAW } onClick={ e => setMode(modes.DRAW) }>
            <DrawIcon/>
          </SelectableIcon>
          { (mode == modes.DRAW) && <input type="color" value={ color } onChange={ e => setColor(e.target.value) }/> }
        </div>
      </ToolbarSection>

      <ToolbarSection>
        <SelectableIcon active={ true } onClick={ onScreenshot }>
          <CameraIcon/>
        </SelectableIcon>
        <SelectableIcon active={ true } onClick={ onExport }>
          <ExportIcon/>
        </SelectableIcon>

        <SelectableIcon active={ !bugFormShown } onClick={ () => onShowBugForm(true) }>
          <BugIcon/>
        </SelectableIcon>

      </ToolbarSection>
    </StyledToolbar>

    { battle.blocks.length && <StyledToolbar side='right'>
      <ToolbarSection>
        <SelectableIcon active={ showHierarchy } onClick={ () => setShowHierarchy(state => !state) }>
          <HierarchyIcon/>
        </SelectableIcon>
      </ToolbarSection>
    </StyledToolbar> }
  </>)
}
