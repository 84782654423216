// const fs = require('fs')
// const path = require('path')
// const Handlebars = require('handlebars')
//
// const loadTemplate = (src) => Handlebars.compile(fs.readFileSync(path.resolve(__dirname, src), 'utf8'))

export * as Vec2 from './vec2'

export const useCanvas = (ref, config) => {
  const canvas = ref ? document.querySelector(ref) : document.createElement('canvas')
  canvas.width = config?.width || window.innerWidth
  canvas.height = config?.height || window.innerHeight
  canvas.ratio = canvas.width / canvas.height
  const ctx = canvas.getContext('2d')
  return ctx
}

/* eslint-disable-next-line */
export const loadImage = (src) => new Promise((res) => {
    const image = new Image()
    image.crossOrigin = "anonymous";
    image.src = src
    image.onload = () => res(image)
})

export const download = (data, filename) => {
  const a = document.createElement('a')
  a.setAttribute('href', data)
  a.setAttribute('download', `${filename}.json`)
  document.body.appendChild(a)
  a.click()
  a.remove()
}

export const exportJSON = (obj, filename) => download("data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(obj)), filename)
export const screenshot = (canvas, filename) => download(document.querySelector('canvas').toDataURL('png'), filename)
