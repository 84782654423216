import React from 'react'
import styled from 'styled-components'
import colors from '@/ui/colors'
import { ClickableIcon, CloseIcon } from '@/ui/components/icons'

const ModalContainer = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 8;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
`

const ModalContent = styled.div`
    position: relative;
    height: fit-content;
    max-height: 80vh;
    max-width: 90%;
    padding: 1em;
    box-sizing: border-box;

    background: ${ colors.background };
    border: 2px solid ${ colors.dark };
    border-radius: .3em;

    -webkit-box-shadow: 10px 4px 21px -4px rgba(0,0,0,0.54);
    -moz-box-shadow: 10px 4px 21px -4px rgba(0,0,0,0.54);
    box-shadow: 10px 4px 21px -4px rgba(0,0,0,0.54);

    align-self: center;
    display: flex;
    justify-content: center;
    flex-direction: column;

    & > * {
        padding: 1em;
    }

    & > div {
        overflow-y: auto;
    }

`

const Close = styled.span`
position: absolute;
padding: 0;
top: -.5em;
left: -.5em;
font-size: 2em;
`

export const Modal = ({ open, children, onClose }) => {
    if (!open) return null
    return <ModalContainer>
        <ModalContent>
            { onClose && <Close><ClickableIcon onClick={ onClose }><CloseIcon/></ClickableIcon></Close> }
            <div>
            { children }
            </div>
        </ModalContent>
    </ModalContainer>
}
