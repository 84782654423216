import React from 'react'
import styled from 'styled-components'
import Login from '@/ui/pages/login'
import { UserProvider } from '@/contexts/user'
import { isTouchDevice } from '@/contexts/input'
import { Modal } from '@/ui/components/modal'
import { Link } from '@/ui/components/link'

const Body = styled.div`
  width: 100%;
  height: 100%;
  background: url(public/bg.jpg);
  background-size: cover;
`

const BottomBar = styled.div`
  position: absolute;
  z-index: 10;
  left: 0;
  bottom: 0;
  background: white;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 1em;
`

import { Session, Logout } from '@/services/user'

const isNotificationShown = (id) => !!+(window.localStorage.getItem(`shown-${id}`) || 0)
const setNotificationShown = (id) => window.localStorage.setItem(`shown-${id}`, 1)

const MOBILE_BETA_CONTROLS_NOTIFICATION = 'MOBILE_BETA_CONTROLS'

!isTouchDevice() && setNotificationShown(MOBILE_BETA_CONTROLS_NOTIFICATION)

const ACTIVE_NOTIFICATION = MOBILE_BETA_CONTROLS_NOTIFICATION
const Base = ({ children }) => {
  const [loading, setLoading] = React.useState(true)
  const [user, setUser] = React.useState(null)

  const [showingNotification, setShowingNotification] = React.useState(!isNotificationShown(ACTIVE_NOTIFICATION))

  const handleCloseNotification = () => {
    setShowingNotification(false)
    setNotificationShown(ACTIVE_NOTIFICATION)
  }

  const InitUser = async () => {
    const user = await Session()
    if (user) setUser(user)
    setLoading(false)
  }

  React.useEffect(() => {
    InitUser()
  }, [])

  if (loading) return <h1>Loading</h1>

  if (!user) return <Body>
    <Login onLogin={ setUser }/>
      <BottomBar>
        <a href="doc/">Guide</a>
        <span>|</span>
        <span>
        Join our <a href="https://discord.gg/SvdAuCZsVC" target="discord">discord</a> community for updates and new beta phases
        </span>
      </BottomBar>
  </Body>
  return (<Body>
    <UserProvider user={ user } logout={ () => {
      window.localStorage.clear()
      Logout()
      setUser(null) 
    }}>
      { children }
      <BottomBar>
        <a href="doc/">Guide</a>
        <span>|</span>
        <span>
        Join our <a href="https://discord.gg/SvdAuCZsVC" target="discord">discord</a> community for updates and new beta phases
        </span>
      </BottomBar>
      <Modal open={ showingNotification } onClose={ handleCloseNotification }>
        <h3>Tactile devices support is now available!</h3>
        <h5>mobile beta controls are in testing stage, please report any bug you find either in the app or in discord</h5>
        <p>Zoom in and out with <b>the sidebar buttons</b></p>
        <p>Drag with <b>two fingers</b> to move the camera</p>
        <p>Rotate selected blocks <b>with two fingers</b></p>
        <p>Drag with <b>one finger</b> to use the area selector</p>
        <p><b>Tap blocks</b> to select them</p>
        <p><b>Doubletap blocks</b> to select their whole unit</p>
        <p>Drag <b>selected blocks</b> to move them</p>
        <h5>Status markers and more fancy stuff is in the oven, please be patient</h5>
      </Modal>
    </UserProvider>
  </Body>)
}
export default Base
