import React from 'react'
import { Modal } from '@/ui/components/modal'

import { FormItem, Button } from '@/ui/components/form'

export const ConfigurationModal = ({
  open,
  battle,
  onChange,
  onClearBlocks,
  onClearPaths,
  onClose,
}) => {
  const [name, setName] = React.useState(battle.name)
  const [scale, setScale] = React.useState(battle.scale)

  return <Modal open={ open } onClose={ onClose }>
    <h3>Battle and Battlefield</h3>
    <FormItem>
      <label>Battle name</label>
      <input type="text" defaultValue={ name } onChange={ e => setName(e.target.value)}/>
    </FormItem>

    <FormItem>
      <label>Block scale</label>
      <input type="number" defaultValue={ scale } onChange={ e => setScale(e.target.value) }/>
    </FormItem>

    <Button onClick={() => onChange({ name, scale }) }>Save</Button>

    <h3>Elements</h3>
    <FormItem>
      <label>Clear all blocks ({ battle.blocks.length })</label>
      <Button onClick={ () => confirm('Are you sure?') && onClearBlocks() }>Clear</Button>
    </FormItem>

    <FormItem>
      <label>Clear all drawings ({ battle.paths.length })</label>
      <Button onClick={ () => confirm('Are you sure?') && onClearPaths() }>Clear</Button>
    </FormItem>
  </Modal>
}
